<template>
    <div class="card-stack">
        <!-- Undo & Save Buttons -->
        <portal
            v-if="activated"
            to="program-edit-actions"
        >
            <b-btn
                variant="light"
                class="btn-min-width mr-3"
                :disabled="!hasUnsavedChanges"
                @click="program.reset()"
            >
                {{ $t('common.actions.undoChanges') }}
            </b-btn>

            <btn-resource
                variant="primary"
                class="btn-min-width"
                :resource="program"
                :disabled="!hasUnsavedChanges"
                @click="program.save()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <!-- Program Details Form -->
        <b-card>
            <b-form @submit.prevent="program.save()">
                <input
                    type="submit"
                    class="d-none"
                >

                <h4 class="mb-4">
                    {{ $t('programs.edit.nav') }}
                </h4>

                <multi-language-tabs
                    class="mb-4"
                    :include-locales="['nl']"
                >
                    <template #default="{locale}">
                        <b-row>
                            <b-col>
                                <b-form-group
                                    :label="$t('programs.model.title.label')"
                                    :invalid-feedback="program.getErrors('title', locale)"
                                    :state="!program.getErrors('title', locale)"
                                >
                                    <b-input v-model="program.title[locale]" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="auto">
                                <b-form-group
                                    v-if="program.customAttributes"
                                    :label="$t('programs.model.color.label')"
                                    :invalid-feedback="_.get(program.errors, 'customAttributes.color')"
                                    :state="!_.get(program.errors, 'customAttributes.color')"
                                >
                                    <v-swatches
                                        v-model="program.customAttributes.color"
                                        :trigger-style="{width: '36px', height: '36px'}"
                                        swatch-size="36"
                                        popover-x="left"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-form-group
                            :label="$t('programs.model.body.label')"
                            :invalid-feedback="program.getErrors('body', locale)"
                            :state="!program.getErrors('body', locale)"
                        >
                            <b-textarea
                                v-model="program.body[locale]"
                                :placeholder="program.getTranslation('body', 'placeholder')"
                                rows="7"
                            />
                        </b-form-group>
                    </template>
                </multi-language-tabs>

                <b-form-group
                    :label="$t('programs.model.privacy.label')"
                    :invalid-feedback="program.errors.privacy"
                    :state="!program.errors.privacy"
                >
                    <radio-group-post-privacy v-model="program.privacy" />
                </b-form-group>
            </b-form>
        </b-card>

        <!-- Delete Program Box -->
        <dangerous-action-card
            :confirmation-message="$t('common.messages.deleteConfirmation', {object: program.getTranslatedAttribute('title')})"
            :title="$t('programs.actions.deleteProgram')"
            :message="$t('common.messages.deleteWarning')"
            :resource="program"
            @confirmed="deleteProgram"
        />
    </div>
</template>

<script>
import DangerousActionCard from '@/components/common/DangerousActionCard';
import MultiLanguageTabs from '@/components/common/MultiLanguageTabs';
import VSwatches from 'vue-swatches';
import {Program} from '@/models/Program';
import {detainFeedback} from '@/library/helpers';
import KeptAlive from '@/components/common/mixins/KeptAlive';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';
import RadioGroupPostPrivacy from '@/components/common/form/RadioGroupPostPrivacy';

export default {
    name: 'ProgramEdit',
    components: {MultiLanguageTabs, DangerousActionCard, RadioGroupPostPrivacy, VSwatches},
    mixins: [ConfirmLeaveUnsaved, KeptAlive],
    props: {
        program: {
            type: Program,
            required: true,
        },
    },
    computed: {
        hasUnsavedChanges() {
            const changes = this.program.changed();

            return !!changes && !(changes.length === 1 && changes.includes('chapters'));
        },
    },
    created() {
        if (!this.program.customAttributes) {
            this.program.set('customAttributes', this.program.defaults().customAttributes);

            this.program.save();
        }
    },
    methods: {
        async deleteProgram() {
            await this.program.delete();

            detainFeedback(() => {
                // Navigate back to programs index page.
                this.$router.push(this.$me.getLocation('programs.index'));
            });
        },
    },
};
</script>
